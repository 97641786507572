@import "partials/variables";
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@300;400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Zilla+Slab:wght@500&display=swap');
body{
   font-family: 'Roboto', sans-serif;
   font-weight: 400;
}
img{
   max-width: 100%;
}
a{
   color: #0000EE;
}
.advertise-area{
   background-color: rgb(8, 8, 8);
   .advertising{
      h4{
         font-size: 16px;
         font-weight: 400;
         margin: 0;
         color: #ddd;
      }
   }
}

//Header area
.header-area{
   background-color: #4249c4;
   padding: 20px 0;
   position: relative;

   .col-sm-8{
      position: inherit;
   }

   .logo{
      h2{
         font-size: 24px;
         margin: 0;
      }
      a{
         text-decoration: none;
         color: #fff;
      }
   }
   .navigation-area{
      ul{
         margin: 0;
         padding:3px 0;
         list-style: none;
         li{
            display: inline;
            a{
               color: #fff;
               font-weight: 400;
               display: inline-block;
               padding: 0 10px;
               font-size: 16px;
               &:last-child{
                  padding-right:0;
               }
            }
         }
      }
   }
}

.content{
   span.date_time{
      font-family: 'Oswald', sans-serif;
      font-weight:300;
      font-size: 14px;
   }
}

.blog-title {
   margin-bottom: 20px;
   font-family: 'DM Sans', sans-serif;
   font-size: 34px;
   line-height: 44px;
   font-weight: 700;
   margin-top: 0;
}

.blog-img{
   margin-bottom: 20px;
   border-radius: 10px;
   box-shadow: 0 3px 11px -5px #000;
   margin-top: 20px;
}
.body-content{
   margin-top: 10px;
   margin-bottom: 20px;
   -webkit-align-self: center;
   -ms-flex-item-align: center;
   -ms-grid-row-align: center;
   align-self: center;
   color: #444;
   font-size: 18px;
   line-height: 28px;
   font-weight: 400;
}
.subtitle {
   font-size: 26px;
   font-weight: 600;
   margin-bottom: 20px;
}
.single-review{
   padding: 25px;
   border: 1px solid rgba(189,189,189,.5);
   border-radius: 8px;
   background-color: #e4f3fb;
   margin-bottom: 10px;
}
.review-stars{
   font-size: 26px;
   i{
      color: #FFC83D;
   }
}
.review-content{
   color:#2F1E72;
   font-family: 'DM Sans', sans-serif;
   h4{
      font-weight: 600;
      margin-bottom: 10px;
      margin-top: 0;
   }
   p{
      font-weight: 400;
      font-family: 'DM Sans', sans-serif;
      font-size: 17px;
   }
}
.breadcrumbs {
   font-family: Oswald,sans-serif;
   color: #666;
   font-size: 14px;
   font-weight: 400;
   margin: 20px 0px 5px;
}

.sidebar_title {
   margin-top: 30px;
   border-style: none none solid;
   border-width: 1px;
   border-color: #000 #000 hsla(0,0%,90.7%,.5);
   font-family: Oswald,sans-serif;
   color: #666;
   font-size: 20px;
   font-weight: 300;
   padding-bottom: 5px;
}
.social_icons{
   a{
      margin:0 14px 0 0;
      font-size: 16px;
      &:last-child{
         margin-right: 0;
      }
   }
   .facebook{
    width: 50px;
    height: 50px;
    background-color: #3b5998;
    display: inline-block;
    color: #fff;
    border-radius: 8px;
    padding: 17px 0;
   }
   .twitter{
      width: 50px;
      height: 50px;
      background-color: #1da1f2;
      display: inline-block;
      color: #fff;
      border-radius: 8px;
      padding: 17px 0;
   }
   .instagram{
      width: 50px;
      height: 50px;
      background-color: #703906;
      display: inline-block;
      color: #fff;
      border-radius: 8px;
      padding: 17px 0;
   }
   .pinterest{
      width: 50px;
      height: 50px;
      background-color: #bd081c;
      display: inline-block;
      color: #fff;
      border-radius: 8px;
      padding: 17px 0;
   }
}
.heading-link {
   a{
      color: #0000EE;
      text-decoration: underline;
   }
   margin-bottom: 40px;
   font-size: 24px;
   line-height: 40px;
   font-weight: 600;
   text-align: center;
}

.product-images{
   overflow: hidden;
   img{
      max-width: 100%;
   }
   .big_images{
      width: 49%;
      float: left;
      margin-right: 1%;
   }
   .small_images{
      width: 49%;
      float: left;
      margin-left:1%;
      img{
        width: 50%;
        float: left;
      }
   }
}

.grid-layout{
   margin-bottom: 23px;
   margin-top: 20px;
   img{
      width: 12.1%;
      display: inline-block;
   }
}
.special-offer-block {
   padding: 15px;
   border: 1px solid #000;
   border-radius: 1px;
   background-color: #fff8d5;
   .special-p-reasons {
      margin-bottom: 10px;
      font-family: 'DM Sans',sans-serif;
      font-size: 20px;
      line-height: 26px;
      font-weight: 400;
      a{
         text-decoration: underline;
      }
  }
}

.check-availability{
   a{
      display: block;
      margin-top: 17px;
      margin-bottom: 20px;
      padding-top: 15px;
      padding-bottom: 15px;
      border: 1px solid #254921;
      font-size: 25px;
      font-weight: 700;
      text-align: center;
      padding: 9px 15px;
      background-color: #3898ec;
      color: #fff;
      line-height: inherit;
      text-decoration: none;
      cursor: pointer;
      border-radius: 0;
      box-shadow: 0 2px 4px 0px #999;
   }
}
.offer-block{
   margin-bottom: 15px;
   font-size: 18px;
   .cta-bottom{
      margin-bottom: 20px;
      font-family: Oswald,sans-serif;
      color: #333;
      font-weight: 400;
   }
}
.testimonial_content{
   margin-top: 40px;
   margin-bottom: 40px;
   background-color: #f8f8f8;
   color: #353640;  
   font-weight: 400;
}

.single-testimonials{
   padding-top: 46px;
   padding-bottom: 120px;
   .author-thum{
      width: 96px;
      height: 96px;
      margin:0 auto;
      img{
         border-radius: 500px;
      }
   }
   .author-name{
      font-size: 16px;
      font-weight: 300;
   }
   p{

   margin-top: 20px;
   margin-bottom: 20px;
   font-family: 'DM Sans', sans-serif;
   font-size: 26px;
   color: #353640;
   line-height: 40px;
   
   }

}
   .owl-dots{
      position: absolute;
      bottom: 40px;
      transform: translate(-50%,0);
      -ms-transform: translate(-50%,0);
      -webkit-transform: translate(-50%,0);
      -moz-transform: translate(-50%,0);
      left: 50%;
      .owl-dot{
         background-color: rgba(34,34,34,.4) !important;
         width:7px;
         height: 7px;
         border-radius: 100%;
         margin-left: 5px;
         margin-right: 5px;
         
      }
      .owl-dot.active{
         background-color: #000 !important;
      }
   }

   .popuparea {
      background-color: rgba(105,107,106,.7);
      position: fixed;
      width: 100%;
      z-index: 9;
      bottom: 0;
      color: #fff;
      padding-top: 5px;
      padding-bottom: 5px;
      border: 1px solid #000;
      .offer-text{
         text-shadow: 0 0 1px #474343;
         p{
            margin-bottom: 0;
         }
      }
      .check_button{
         a{
            background-color: #3898ec;
            box-shadow: 0 8px 13px -11px #000;
            font-size: 24px;
            line-height: 26px;
            text-decoration: none;
            padding: 9px 15px;
            display: block;
            color: #fff;
         }
      }
  }

  .footer-area{
   padding-top: 25px;
   padding-bottom: 60px;
   background-color: #333;
   color: #dadada;
   font-weight: 400;
   a{
      color: #fff;
      text-decoration: underline;
   }
   .link_link{
      display: block;
      margin-top: 5px;
   }
  }
.instagram{
   display: -webkit-box;
   display: -webkit-flex;
   display: -ms-flexbox;
   display: flex;
   padding-right: 9px;
   padding-left: 9px;
   -webkit-box-pack: center;
   -webkit-justify-content: center;
   -ms-flex-pack: center;
   justify-content: center;
   -webkit-box-align: stretch;
   -webkit-align-items: stretch;
   -ms-flex-align: stretch;
   align-items: stretch;
   -webkit-box-flex: 1;
   -webkit-flex: 1;
   -ms-flex: 1;
   flex: 1;
   -webkit-perspective: 1000px;
   perspective: 1000px;
}
  .instagram-previews {
   position: relative;
   z-index: 1;
   display: -webkit-box;
   display: -webkit-flex;
   display: -ms-flexbox;
   display: flex;
   width: 33.33%;
   -webkit-flex-wrap: wrap;
   -ms-flex-wrap: wrap;
   flex-wrap: wrap;
   -webkit-box-align: start;
   -webkit-align-items: flex-start;
   -ms-flex-align: start;
   align-items: flex-start;
   -webkit-align-content: center;
   -ms-flex-line-pack: center;
   align-content: center;
   -webkit-box-flex: 0;
   -webkit-flex: 0 0 auto;
   -ms-flex: 0 0 auto;
   flex: 0 0 auto;
   text-decoration: none;
   .instagram-photo-link {
      position: relative;
      overflow: hidden;
      width: 50%;
      padding: 6px;
      -webkit-box-flex: 0;
      -webkit-flex: 0 auto;
      -ms-flex: 0 auto;
      flex: 0 auto;
  }
}
.instagram-info {
   position: relative;
   z-index: 1;
   display: -webkit-box;
   display: -webkit-flex;
   display: -ms-flexbox;
   display: flex;
   padding: 24px;
   -webkit-box-orient: vertical;
   -webkit-box-direction: normal;
   -webkit-flex-direction: column;
   -ms-flex-direction: column;
   flex-direction: column;
   -webkit-box-pack: center;
   -webkit-justify-content: center;
   -ms-flex-pack: center;
   justify-content: center;
   -webkit-box-align: center;
   -webkit-align-items: center;
   -ms-flex-align: center;
   align-items: center;
   -webkit-box-flex: 1;
   -webkit-flex: 1;
   -ms-flex: 1;
   flex: 1;
   border: 6px solid #fff;
   background-color: #f6f6f6;
   -webkit-transition: -webkit-transform .4s;
   transition: transform .4s;
   transition: transform .4s,-webkit-transform .4s;
   color: #151515;
   text-align: center;
   text-decoration: none;
   
}
.w-inline-block {
   max-width: 100%;
   text-decoration: none !important;
   .title {
      z-index: 1;
      margin-bottom: 4px;
      font-family: 'Great Vibes', cursive;
      font-size: 24px;
      line-height: 32px;
      letter-spacing: 0;
      font-weight: 300;
      
  }
  .text-color {
   color: #ff6852;
   }
   .instagram-heading {
      margin-top: -4px;
      margin-bottom: 0;
      font-size: 32px;
      line-height: 36px;
  }
}
.instagram-logo {
   width: 144px;
   max-width: 44%;
   margin-bottom: 40px;
}

.instagram-photo-link{
   position: relative;
   img{
      transition: 1s;
      -moz-transition: 1s;
      -webkit-transition: 1s;
      -ms-transition: 1s; 
      overflow: hidden;
   }
  
   .hover-animation{
      background-image:url(../img/instagram-color.svg);
      position: absolute;
      visibility: hidden;
      transition: 0.5s;
      -moz-transition: 0.5s;
      -webkit-transition: 0.5s;
      -ms-transition: 0.5s;
      opacity: 0;
      z-index: 1;
      width: 44px;
      height: 44px;
      margin: auto;
      border-radius: 50%;
      background-color: #fff;
      background-position: 50% 50%;
      background-size: 20px;
      background-repeat: no-repeat;
      top:50%;
      left:50%;
      margin-left: -22px;
      margin-top:-22px;
   }
}
.instagram-photo-link:hover .hover-animation{
   visibility: visible;
   opacity: 1;
   width: 44px;
   height: 44px;
}
.instagram-photo-link:hover img{
   transform: scale(1.2);
  -moz-transform: scale(1.2);
  -webkit-transform: scale(1.2);
  -o-transform: scale(1.2);
  -ms-transform: scale(1.2);  
}
.instagram-area {
   margin-bottom: 40px;
}

.mobile-icon{
      position: absolute;
      top: 5px;
      right: 0;
      z-index: 2;
      cursor: pointer;
      display: none;
      div{
        margin-bottom: 8px;
        &.first{
          background-color: #000;
          height: 3px;
          width: 30px;
        }
        &.second{
          background-color: #000;
          height: 3px;
          width: 10px;
        }
        &.third{
          background-color: #000;
          height: 3px;
          width: 20px;
        }
      }
    }
     .mobile-menu-nav{
      display: none;
    }  


.heading-body-center{
   margin-top: 40px;
   margin-bottom: 40px;
   font-family: 'Zilla Slab',sans-serif;
   color: #120655;
   font-weight: 500; 
   font-size: 24px;
   line-height: 30px;   
}

.popuparea{
   transition: 0.5s;
   -webkit-transition: 0.5s;
   -moz-transition: 0.5s;
   -o-transition: 0.5s;
   -ms-transition: 0.5s;
   opacity: 0;
   visibility: hidden;
}

 .slicknav_btn {
   position: relative;
   display: block;
   vertical-align: middle;
   float: right;
   padding: 0.438em 0.625em 0.438em 0.625em;
   line-height: 1.125em;
   cursor: pointer; }
   .slicknav_btn .slicknav_icon-bar + .slicknav_icon-bar {
     margin-top: 0.188em; }
 
 .slicknav_menu {
   *zoom: 1; }
   .slicknav_menu .slicknav_menutxt {
     display: block;
     line-height: 1.188em;
     float: left; }
   .slicknav_menu .slicknav_icon {
     float: left;
     width: 1.125em;
     height: 0.875em;
     margin: 0.188em 0 0 0.438em; }
     .slicknav_menu .slicknav_icon:before {
       background: transparent;
       width: 1.125em;
       height: 0.875em;
       display: block;
       content: "";
       position: absolute; }
   .slicknav_menu .slicknav_no-text {
     margin: 0; }
   .slicknav_menu .slicknav_icon-bar {
     display: block;
     width: 1.125em;
     height: 0.125em;
     -webkit-border-radius: 1px;
     -moz-border-radius: 1px;
     border-radius: 1px;
     -webkit-box-shadow: 0 1px 0 rgba(0, 0, 0, 0.25);
     -moz-box-shadow: 0 1px 0 rgba(0, 0, 0, 0.25);
     box-shadow: 0 1px 0 rgba(0, 0, 0, 0.25); }
   .slicknav_menu:before {
     content: " ";
     display: table; }
   .slicknav_menu:after {
     content: " ";
     display: table;
     clear: both; }
 
 .slicknav_nav {
   clear: both; }
   .slicknav_nav ul {
     display: block; }
   .slicknav_nav li {
     display: block; }
   .slicknav_nav .slicknav_arrow {
     font-size: 0.8em;
     margin: 0 0 0 0.4em; }
   .slicknav_nav .slicknav_item {
     cursor: pointer; }
     .slicknav_nav .slicknav_item a {
       display: inline; }
   .slicknav_nav .slicknav_row {
     display: block; }
   .slicknav_nav a {
     display: block; }
   .slicknav_nav .slicknav_parent-link a {
     display: inline; }
 
 .slicknav_brand {
   float: left; }
 
 .slicknav_menu {
   font-size: 16px;
   box-sizing: border-box;
  
   padding: 5px; }
   .slicknav_menu * {
     box-sizing: border-box; }
   .slicknav_menu .slicknav_menutxt {
     color: #fff;
     font-weight: bold;
     text-shadow: 0 1px 3px #000; }
   .slicknav_menu .slicknav_icon-bar {
     background-color: #fff; }
 
 .slicknav_btn {
   margin: 5px 5px 6px;
   text-decoration: none;
   text-shadow: 0 1px 1px rgba(255, 255, 255, 0.75);
 }
 
 .slicknav_nav {
   background: #242424 none repeat scroll 0 0;
   color: #fff;
   font-size: 0.875em;
   list-style: outside none none;
   margin: 0;
   overflow: hidden;
   padding: 0;
 }
   .slicknav_nav ul {
     list-style: none;
     overflow: hidden;
     padding: 0;
     margin: 0 0 0 20px; }
   .slicknav_nav .slicknav_row {
     padding: 5px 10px;
     margin: 2px 5px; }
     .slicknav_nav .slicknav_row:hover {
       background: #ccc;
       color: #fff; }
   .slicknav_nav a {
     padding: 5px 10px;
     text-decoration: none;
     color: #fff; }
     .slicknav_nav a:hover {
       background: #fff;
       color: #000; }
   .slicknav_nav .slicknav_txtnode {
     margin-left: 15px; }
   .slicknav_nav .slicknav_item a {
     padding: 0;
     margin: 0; }
   .slicknav_nav .slicknav_parent-link a {
     padding: 0;
     margin: 0; }
 
 .slicknav_brand {
   color: #fff;
   font-size: 18px;
   line-height: 30px;
   padding: 7px 12px;
   height: 44px; }
.mobile_menu{
   display: none;
}
.slicknav_btn {
   position: absolute;
   top: 16px;
   right: 0;
}

@import "partials/responsive";



