.container{transition:all 0.9s ease 0s;-moz-transition:all 0.9s ease 0s;-webkit-transition:all 0.9s ease 0s;-o-transition: all 0.9s ease 0s;}



@media only screen and (min-width: 992px) and (max-width: 1200px) {

   .social_icons a {
      margin: 0 0px 0 0;
  }
  .single-testimonials {
   padding-bottom: 60px;
}


}


/* Tablet Layout: 768px. */

@media only screen and (min-width: 768px) and (max-width: 991px) {
   .grid-layout img {
      width: 12%;
  }



}
/* Mobile Layout: 320px. */

@media only screen and (max-width: 767px) {

   .single_sidebar {
      text-align: center;
   }
   .instagram {
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
  }

.instagram-previews {
   width: 100%;
}
.popuparea .offer-text {
    display: none;
}
.video-content iframe {
   width: 100% !important;
}
.product-images .big_images {
   width: 100%;
   float: none;
   margin-right: 0;
}
.product-images .small_images {
   width: 100%;
   float: none;
   margin-left: 0;
}
.grid-layout img {
   width: 24.2%;
   margin-bottom: 9px;
}
.mobile_menu{
   display: block;
}
.navigation-area{
   display: none;
}
.blog-title {
   font-size: 24px;
   line-height: 32px;
}

.slicknav_nav {
   background:inherit;
   border: 1px solid#fff;
   border-radius: 5px;
   margin-top: 10px;
}

}


/* Wide Mobile Layout: 480px. */

@media only screen and (min-width: 480px) and (max-width: 767px) {



}